import Login from "@/Capacitacion/Participante/Autenticacion/Login";
import { useSeccion } from "@/Template/Seccion";
import { Button, Card, Col, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { Asistencia as Model, conAsistencia, conEvento } from "../Models";
import { useCallback, useEffect, useState } from "react";
import Cargando from "@/Components/Cargando";
import d from 'dayjs'
import { findEvento } from "@/Capacitacion/Context/Eventos";
export default function Asistencia() {
    const { data: { seccion } } = useSeccion();
    return <Row className="m-5 p-5">
        <Col>
            {seccion ? <Marcar /> : <Col lg={{ span: 4, offset: 4 }}>
                <Card><Card.Body className="menu-login"><Login /></Card.Body></Card>
            </Col>}
        </Col>
    </Row>
}
function Marcar() {
    const { codigo } = useParams<any>();
    const [asistentes, setAsistentes] = useState<Model>()
    const actualizar = useCallback(() => { conAsistencia().detalle(codigo, setAsistentes) }, [codigo]);
    // eslint-disable-next-line
    useEffect(() => actualizar(), [])
    if (!asistentes) return <Cargando />
    return <Ver a={asistentes} actualizar={actualizar} />
}
function Ver({ a, actualizar }: { a: Model, actualizar: () => void }) {
    const { data: { User } } = useSeccion();
    const { id, evento, asistentes } = a;
    const [c, set] = useState<any>(findEvento(evento));
    const init = useCallback(() => { conEvento().detalle(evento, set); }, [evento])
    // eslint-disable-next-line
    useEffect(() => init(), [])
    const part = c.participantes?.find((p: any) => User.id === p.id)
    if (part) {
        if (a.estatus === 1) {
            if (val(a)) {
                return <Row className="m-5 p-5">
                    <Col lg={{ span: 8, offset: 2 }} className="p-3"><h1 className="text-primary text-center">{c.nombre}</h1></Col>
                    <Col lg={{ span: 4, offset: 4 }} className="p-3">
                        {asistentes === -1 ? <Button block onClick={() => conAsistencia().marcar(id, actualizar)}>
                            <i className="fas fa-hand fa-10x" /><br />
                            <b>MARCAR ASISTENCIA</b>
                        </Button> : <Button block variant="success">
                            <i className="fas fa-check fa-10x" /><br />
                            <b>GRACIAS POR ASISTIR</b>
                        </Button>}
                    </Col>
                </Row>
            } else {
                return <Row className="m-5 p-5">
                    <Col lg={{ span: 8, offset: 2 }} className="p-3"><h1 className="text-primary text-center">{c.nombre}</h1></Col>
                    <Col lg={{ span: 4, offset: 4 }} className="p-3">
                        <Button block variant="warning">
                            <i className="fas fa-clock fa-10x" /><br />
                            <b>ESTAS FUERA DE HORARIO</b>
                        </Button>
                    </Col>
                </Row>
            }
        }
    }
    return <Row className="m-5 p-5">
        <Col lg={{ span: 4, offset: 4 }} className="p-3">
            <Button block variant="danger">
                <i className="fas fa-hand fa-10x" /><br />
                <b>NO HAY CLASE EN ESTE LINK</b>
            </Button>
        </Col>
    </Row>

}

function val({ fecha: f, inicio, final }: Model) {
    const fecha = parseInt(d(f).format('YYYYMMDD'));
    const dia = parseInt(d().format('YYYYMMDD'));
    const ini = getHora(inicio);
    const fin = getHora(final);
    const hora = parseInt(d().format('HHmm'));
    if ((fecha === dia) && (fin >= hora && hora >= ini)) {
        return true
    }
    return false
}



function getHora(t: string) {
    console.log(t)
    const h = t.split(':');
    return parseInt(d().set('hour', parseInt(h[0])).set('minute', parseInt(h[1])).set('second', 0).format('HHmm'));
}

